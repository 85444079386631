.navbar {
  background-color: var(--main-color);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  color: white;
}

.navbar .nav-container {
  position: relative;
  max-width: 1000px;
  padding: 0 40px;
  margin: auto;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.hamburger {
  display: block;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: white;
}

.hamburger.active .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.active .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
  -webkit-transform: translateY(8px) rotate(45deg);
}

.hamburger.active .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
  -webkit-transform: translateY(-8px) rotate(-45deg);
}

.nav-menu {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: var(--main-color);
  display: flex;
  flex-direction: column;
  text-align: center;
  list-style: none;
  border: none;
  z-index: 10;
}

.nav-item {
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: larger;
  /* Disappear by default */
  height: 0;
  overflow: hidden;
  transition: height 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.nav-menu.active .nav-item {
  height: 50px;
  padding: 10px 0;
  /* padding: 5px; */
  transition: height 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
