.home-bg {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-color: #e6e6e6;
  z-index: -1;
}

.home-container {
  position: relative;
  top: 60px;
  margin: 0 auto;
  max-width: 1000px;
  min-height: calc(100vh - 60px);
  background-color: white;
  padding-bottom: 60px;
  transition: top 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.home-container.active {
  top: 210px;
  transition: top 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-btn {
  margin: 50px 0;
}

.search {
  width: 320px;
  height: 40px;
  font-size: larger;
  border-radius: 30px;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.search input {
  height: 35px;
  font-family: inherit;
  font-size: large;
  width: 90%;
  border: none;
  margin: 0 10px;
}

.search input:focus {
  outline: none;
}

.cancel-search {
  cursor: pointer;
}
