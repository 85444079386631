.form-list {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 5px 0;
}

.form-list input {
  width: auto;
  flex-grow: 1;
}

.form-list i {
  margin: 0 5px;
}

.form-config {
  display: flex;
  align-items: center;
}

.form-config input[type="number"] {
  width: 60px;
}

.form-config input[type="checkbox"] {
  width: 25px;
}
