.password-entity {
  background-color: var(--main-color);
  width: 400px;
  height: 120px;
  border-radius: 20px;
  padding: 20px 25px;
  margin-top: 50px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.popup {
  position: absolute;
  background-color: white;
  color: var(--main-color);
  top: 20px;
  right: 20px;
  padding: 5px 0px;
  border-radius: 3px;
  box-shadow: 0 0 3px black;
  z-index: 10;
}

.popup-item {
  font-size: large;
  font-weight: 500;
  line-height: 35px;
  padding: 0 20px;
  text-align: center;
  cursor: pointer;
}

.popup-item:first-child {
  border-bottom: 1px solid rgb(189, 189, 189);
}

.popup-item:hover {
  background-color: rgb(233, 233, 233);
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info h3 {
  font-size: medium;
}

.password-btns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: larger;
  height: 100%;
  padding-top: 5px;
}

.eye {
  width: 25px;
  height: 20px;
  font-size: medium;
  position: relative;
}

.eye::before {
  content: "Copied";
  position: absolute;
  top: 45px;
  right: 0;
  background-color: var(--main-color);
  padding: 5px;
  border-radius: 7px;
  font-size: small;
  display: none;
}

.eye::after {
  content: "";
  position: absolute;
  top: 42px;
  right: 10px;
  width: 5px;
  height: 5px;
  background-color: var(--main-color);
  transform: rotate(45deg);
  display: none;
}

.eye.active::before,
.eye.active::after {
  display: block;
}
