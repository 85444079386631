.login-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--main-color);
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
}

.login-box h1 {
  color: var(--main-color);
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 5px;
}

.form-control {
  width: 70%;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
}

.form-control h4 {
  width: 100%;
  margin-bottom: 3px;
  font-size: 0.9rem;
  font-weight: 400;
}

.form-control input {
  width: 97%;
  height: 40px;
  border-radius: 5px;
  border-color: black;
  border-width: 2px;
  font-size: 1rem;
  padding: 0 5px;
}

.incorrect-creds {
  color: red;
  font-size: small;
  height: 10px;
}

.form-btn {
  width: 70%;
  margin-top: 10px;
  height: 45px;
  border: none;
  background-color: var(--main-color);
  font: inherit;
  font-size: 0.9rem;
  cursor: pointer;
  color: white;
}

.form-btn:disabled {
  background-color: var(--disabled-color);
}

.form-btn:hover {
  transform: scale(1.01);
}
