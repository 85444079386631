.delete-modal {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
  text-align: center;
  gap: 30px;
}

.delete-modal h3 {
  color: var(--main-color);
  font-weight: 500;
}

.delete-btns {
  display: flex;
  gap: 10px;
}

.cancel-btn {
  width: 100px;
  height: 30px;
  border: none;
  font-family: inherit;
  cursor: pointer;
  color: black;
}

.cancel-btn:hover {
  background-color: #e6e6e6;
}

.confirm-btn {
  width: 100px;
  height: 30px;
  border: none;
  font-family: inherit;
  cursor: pointer;
  background-color: var(--main-color);
  color: white;
}

.confirm-btn:hover {
  background-color: #10c1c7;
}
